export const providersIcons = {
    'BILLLINE': 'billine.svg',
    'CRYPTO_NODE': 'settle_pay.png',
    'SETTLE_PAY': 'settle_pay.png',
    'DAXCHAIN': 'daxchain.svg',
    'POINT_PAY': 'point_pay.png',
    'CAPITALIST': 'capitalist.svg',
    'PIASTRIX': 'piastrix.png',
    'COINS_PAID': 'coins_paid.png',
    'INVOICERS': 'invoicers.svg',
    'EXBASE': 'exbase.png',
}