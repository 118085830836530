<template>
  <div class="SquareCoinIcon_root">
    <div :style="style" class="SquareCoinIcon_wrapper">
      <SvgIcon v-if="type === 'svg'"
        :icon="currency"
      ></SvgIcon>
      <img v-else-if="type === 'image'" :src="getImg" alt="">
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/_common/SvgIcon";
export default {
  props: {
    currency: {
      type: String,
      required: true,
      default: 'USD',
    },
    size: {
      type: String,
      default: '18',
    },
    withoutCustomSize: {
      type: Boolean,
      default: false,
    },
    // type: {
    //   type: String,
    //   default: 'svg',
    // },
  },
  components: {
    SvgIcon
  },
  computed: {
    type() {
      return this.currency === 'UACB-NOV22' ? 'image' : 'svg';
    },
    getImg() {
      try {
        return require(`@/assets/icons/${this.currency}.svg`);
      } catch (error) {
        return '';
      }
    },
    style({size, withoutCustomSize}=this) {
      if (withoutCustomSize) return ''
      return `width: ${size ? size : '18'}px; height: ${size ? size : '18'}px;`
    },
  },
};
</script>

<style lang="scss" scoped>
@import './SquareCoinIcon.scss';
</style>
