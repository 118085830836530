<template>
  <div class="ProvidersInfoTable_Row ProvidersInfoTable_Row--route">
    <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--icon"></div>
    <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--title">{{ route.name }}</div>
    <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--deposit">
      <span class="ProvidersInfoTable_MobileTitle">Account type: </span>{{ operationTypeFormatter }}
    </div>
    <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--withdrawal">
      <span class="ProvidersInfoTable_MobileTitle">Limits: </span>
      <div class="ProvidersInfoTable_Property"><span class="ProvidersInfoTable_PropertyName">min:</span> {{ `${route.limits.min_amount} ${currency}` }}</div>
      <div class="ProvidersInfoTable_Property"><span class="ProvidersInfoTable_PropertyName">max:</span> {{ `${route.limits.max_amount} ${currency}` }}</div>
    </div>
    <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--fees">
      <span class="ProvidersInfoTable_MobileTitle">Fees: </span>
      <div class="ProvidersInfoTable_Property"><span class="ProvidersInfoTable_PropertyName">Provider:</span> {{ feesProvider }}</div>
      <div class="ProvidersInfoTable_Property"><span class="ProvidersInfoTable_PropertyName">Client:</span> {{ feesClient }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    route: {
      type: Object,
    },
    currency: {
      type: String,
    },
  },
  methods: {
    fees({percent_fee, static_fee}) {
      let result = ``;
      result += percent_fee ? `${percent_fee}%` : ``;
      result += !!result ? ` + ` : ``;
      result += static_fee ? `${static_fee} ${this.currency}` : ``;
      result = !!result ? result : `0 ${this.currency}`;
      return result;
    }
  },
  computed: {
    feesClient() {
      const {percent_fee, static_fee} = this.route.fee
      return this.fees({percent_fee, static_fee})
    },
    feesProvider() {
      const {client_percent_fee: percent_fee, client_static_fee: static_fee} = this.route.fee
      return this.fees({percent_fee, static_fee})
    },
    operationTypeFormatter() {
      return this.route.operation_type.toUpperCase().charAt(0) + this.route.operation_type.toLowerCase().slice(1);
    },
  },
};
</script>