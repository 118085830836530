<template>
  <div class="ProvidersEmpty">
    <img src="@/assets/img/_common/emptyFees.svg" alt="fees empty img" />
    <div class="ProvidersEmpty_wrapperDesc">You will see providers here when you <span>add the first provider</span></div>
    <router-link to="/providers/provider" class="ProvidersEmpty_add"
      >add provider<span><iconPlus fillColor="#2AF3F3" /></span
    ></router-link>
  </div>
</template>

<script>
import { iconPlus } from '@/assets/icons/common';

export default {
  components: {
    iconPlus,
  },
};
</script>

<style lang="scss">
@import './ProvidersEmpty.scss';
</style>
