<template>
  <div class="Limits_container" v-if="!emptyProviderHandler()">
    <table class="ProviderLimits_table" border="0" cellspacing="0" cellpadding="0">
      <tr class="ProviderLimits_tableHeader">
        <th>
          <div class="ProviderLimits_tableContainer">{{ title }}</div>
        </th>
        <th><div class="ProviderLimits_tableContainer">deposit</div></th>
        <th><div class="ProviderLimits_tableContainer">withdrawal</div></th>
        <th><div class="ProviderLimits_tableContainer">fees</div></th>
      </tr>
      <tbody v-for="(provider, key) in providers" :key="key" :item="provider" :class="[{ providerManyPayments: manyPayTypes(provider) }]">
        <tr class="ProviderLimits_tableContainerSubtitleContainer" v-if="manyPayTypes(provider)">
          <td colspan="4">
            <div class="ProviderLimits_tableContainerSubtitle">
              <div class="ProviderLimits_tableIcon"><SquareCoinIcon currency="default" /></div>
              {{ key.split('_').join(' ') }}
            </div>
          </td>
        </tr>
        <tr
          v-for="(inner, innerKey) in provider.payment_ways"
          :key="innerKey"
          :item="inner"
          :class="[{ providerSingleType: !manyPayTypes(provider) }]"
        >
          <td>
            <div class="ProviderLimits_tableContainer">
              <div class="ProviderLimits_tableIcon" v-if="!manyPayTypes(provider)"><SquareCoinIcon currency="default" /></div>
              {{ manyPayTypes(provider) ? innerKey : key.split('_').join(' ') }}
            </div>
          </td>
          <td>
            <div class="ProviderLimits_tableContainerBody">
              <span><b>min: </b>{{ inner && inner.deposit ? inner.deposit.limit.min : '-' }}</span
              ><span><b>max: </b>{{ inner && inner.deposit ? inner.deposit.limit.max : '-' }}</span>
            </div>
          </td>
          <td>
            <div class="ProviderLimits_tableContainerBody">
              <div class="ProviderLimits_tableContainerBodyInner">
                <span><b>min: </b>{{ inner && inner.withdrawal ? inner.withdrawal.limit.min : '-' }}</span>
                <span><b>max: </b>{{ inner && inner.withdrawal ? inner.withdrawal.limit.max : '-' }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="ProviderLimits_tableContainerBody">
              <span><b>Deposit: </b>{{ feeHandler(inner && inner.deposit && inner.deposit.fee ? inner.deposit.fee : '') }}</span
              ><span
                ><b>Withdrawal: </b>{{ feeHandler(inner && inner.withdrawal && inner.withdrawal.fee ? inner.withdrawal.fee : '-') }}</span
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { isEmpty } from 'ramda';
import SquareCoinIcon from '@/components/_common/SquareCoinIcon/SquareCoinIcon';
export default {
  data: () => ({ isEmpty }),
  components: {
    SquareCoinIcon,
  },
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    providers: {
      type: Object,
      required: false,
    },
  },
  methods: {
    emptyProviderHandler() {
      return this.isEmpty(this.providers[Object.keys(this.providers)[0]].payment_ways);
    },
    feeHandler(fees) {
      if (fees && fees.percent_fee) {
        return fees.static_fee ? fees.percent_fee + `% + ${fees.static_fee}${this.title}` : `${fees.percent_fee}%`;
      } else {
        return '-';
      }
    },
    manyPayTypes(provider) {
      return Object.keys(provider.payment_ways).length > 1 ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
@import './ProviderLimits.scss';
.ProviderLimits_tableContainerSubtitle {
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #fff;
  height: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0 0 16px;
  height: 40px;
}
</style>
