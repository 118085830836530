<template>
  <section class="Limits_root">
    <AppSection title="Fees and limits">
      <div class="Limits_wrapper" slot="content">
        <div class="ProvidersInfoTable" v-if="providers.length">
          <template v-for="providersGroup in providers">
            <div
              class="ProvidersInfoTable_Row ProvidersInfoTable_Row--head"
              :key="providersGroup.currency"
            >
              <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--icon">{{ providersGroup.currency }}</div>
              <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--title"></div>
              <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--deposit">Account type</div>
              <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--withdrawal">Limits</div>
              <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--fees">Fees</div>
            </div>
            <template v-for="provider in providersGroup.providers">
              <div
                class="ProvidersInfoTable_Row ProvidersInfoTable_Row--provider"
                :key="`${ providersGroup.currency }-${ provider.id }`"
              >
                <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--icon">
                  <div class="ProvidersInfoTable_Icon">
                    <img :src="require(`@/assets/img/acquiring/providers/${ provider.iconName }`)" alt="Provider Icon" />
                  </div>
                </div>
                <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--title">{{ provider.name }}</div>
                <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--deposit"></div>
                <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--withdrawal"></div>
                <div class="ProvidersInfoTable_Cell ProvidersInfoTable_Cell--fees"></div>
              </div>
              <ProvidersInfoTableRoute
                v-for="route in provider.payment_routes"
                :key="`${ providersGroup.currency }-${ provider.id }-${ route.id }`"
                :route="route"
                :currency="providersGroup.currency"
              />
            </template>
          </template>
        </div>
        <ProvidersEmpty v-else />
      </div>
      <div slot="tollbar">
        <RoundButton
          class="CreateAndUpdateProvider_backBtn"
          withIcon
          iconType="arrowLeft"
          text="back to dashboard"
          @click="goToDashboard"
        />
      </div>
    </AppSection>
  </section>
</template>

<script>
import AppSection from '@/components/_common/AppSection/AppSection';
import ProviderLimits from '@/components/_common/ProviderLimits/ProviderLimits';
import { mapGetters, mapActions } from 'vuex';
import ProvidersEmpty from '../ProvidersEmpty/ProvidersEmpty';
import ProvidersInfoTableRoute from './ProvidersInfoTableRoute/ProvidersInfoTableRoute';
import { providersIcons } from '@/views/Application/Account/Providers/_helpers/';
import { isEmpty } from 'ramda';
import RoundButton from "@/components/_common/FormElements/RoundButton/RoundButton";

export default {
  data() {
    return {
      providersIcons,
      isEmpty
    };
  },
  components: {
    RoundButton,
    AppSection,
    ProviderLimits,
    ProvidersEmpty,
    ProvidersInfoTableRoute,
  },
  computed: {
    ...mapGetters('providers', ['userProviders']),
    providers() {
      let providers = []
      for (let index in this.userProviders) {
        let provider = JSON.parse(JSON.stringify(this.userProviders[index]))
        provider.payment_routes.forEach( route=> {
          route.currencies.forEach( currency=> {
            let providerIndex = providers.findIndex( provider => provider.currency == currency )
            let localProvider = JSON.parse( JSON.stringify( provider ) )
            localProvider.payment_routes = [ route ]
            localProvider.iconName = this.providersIcons[ localProvider.provider] || 'billine.svg'
            if ( providerIndex == -1 ) {
              providers.push({
                currency,
                providers: [localProvider]
              })
            } else {
              let currencyProvider = providers[providerIndex].providers.find(localProvider=>localProvider.id == provider.id)
              if (currencyProvider) {
                currencyProvider.payment_routes.push(route)
              } else {
                providers[providerIndex].providers.push(localProvider)
              }
            }
          })
        })
      }
      return providers
    }
  },
  methods: {
    ...mapActions('providers', ['apiGetUserProviders']),
    goToDashboard() {
      this.$router.push({ path: '/providers/dashboard' })
    },
  },
  mounted() {
    console.log(this.providers, 'this.userProviders');
    this.apiGetUserProviders();
  },
};
</script>

<style lang="scss">
@import './Limits.scss';
</style>
