<template>
  <div class="RoundButton_root" @click="handleClick" :disabled="disabled">
    <button
      :class="[
        'RoundButton_button',
        {
          ['RoundButton_disabled']: disabled,
        },
      ]"
    >
      <component v-if="withIcon" :is="iconComponent" fillColor="#0F1E30" />
    </button>
    <span v-if="text">{{ text }}</span>
  </div>
</template>

<script>
import { arrowLeft } from '@/assets/icons/arrows';

export default {
  props: {
    text: {
      type: String,
      required: false,
    },
    withIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconType: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    arrowLeft,
  },
  computed: {
    iconComponent() {
      switch (this.iconType) {
        case 'arrowLeft':
          return arrowLeft;
        default:
          return arrowLeft;
      }
    },
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
@import './RoundButton.scss';
</style>
